import styled from 'styled-components'

export const StepWrap = styled.div`
	max-width: 800px;
	margin: auto;
	margin-top: 50px;
	border-top: 1px solid #DCE7EF;
	padding-top: 30px;
`
export const ApplicationType = styled.div`
	max-width: 450px;
	width: 100%;
	margin: auto;
	margin-top: -45px;
	margin-bottom: 59px;
	text-align: center;
	& input {
		display: none;
	}
	& label {
		font-size: 14px;
		font-weight: 800;
		line-height: 20px;
		padding: 13px 40px;
		background: white;
		border: 1px solid #DCE7EF;
	}
	@media(max-width: 480px) {
		& label {
			padding: 13px 20px;
		}
	}
`
export const JointApp = styled.label`
	border-top-left-radius: 40px !important;
	border-bottom-left-radius: 40px !important;
	color: ${props=>props.color};
	box-shadow: ${props=>props.boxShadow};
`
export const SingleApp = styled.label`
	border-top-right-radius: 40px !important;
	border-bottom-right-radius: 40px !important;
	color: ${props=>props.color};
	box-shadow: ${props=>props.boxShadow};
`
export const Icon = styled.span`
	margin-left: 5px;
	display: ${props=>props.display};
`
export const FormFields = styled.div`
	position: relative;
	z-index: 51;
	margin-top: 50px;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: auto;
	grid-column-gap: 30px;
	grid-row-gap: 45px;
	@media(max-width: 600px) {
		grid-template-columns: repeat(1, 1fr);
	}
	& label {
		font-size: 14px;
		font-weight: 600;
		line-height: 20px;
		color: #062C44;
		display: block;
	}
	& input {
		font-size: 20px;
		line-height: 24px;
		font-weight: 800;
		padding: 10px 0 20px;
		border: 0px;
		width: 100%;
		background: rgba(255, 255, 255, 0.001);
	}

	& select {
		margin-top: 8px;
		margin-left: -5px;
		font-size: 20px;
		line-height: 24px;
		font-weight: 800;
		padding: 10px 0 20px;
		border: 0px;
		width: 100%;
		background: rgba(255, 255, 255, 0.001);
		& option {
			font-size: 20px;
			line-height: 24px;
			font-weight: 800;
			padding: 10px 0 20px;
			border: 0px;
			width: 100%;
			background: rgba(255, 255, 255, 0.001);
		}
	}
	& textarea {
		margin-top: 50px;
		width: calc(200% + 30px);
		font-size: 20px;
		line-height: 24px;
		margin-top: 20px;
		padding: 10px;
		z-index: 10;
	}
`
