import React from 'react'
import styled from 'styled-components'
import { Section, SectionTitle, MarkdownContent, SectionButtonWrapper } from "../../components/Section"
import SectionButton from "../../components/Button/SectionButton"
import CheckIcon from "../../components/Icons/CheckIcon"

const OptionsGrid = styled.div`
	max-width: 1120px;
	width: 100%;
	margin: auto;
	margin-top: 39px;
	display: flex;
	align-items: center;
	flex-direction: column;

	& p {
		color: #010920;
		padding: 20px 0 22px;
		text-align: left;
	}
`
const FirstItem = styled.div`
	padding: 90px 80px 82px;
	border-radius: 10px;
	border: 1px solid #98DFFF;
	box-shadow: 0px 15px 45px rgba(152, 223, 255, 0.2);
	@media(max-width: 1200px) {
		padding: 50px 30px;
	}
	@media(max-width: 900px) {
		padding: 30px 20px;
		max-width: 500px;
		margin: auto;
	}
	
`
const Option = styled.div`
	font-size: 16px; 
	font-weight: 800;
	line-height: 26px;
	color: #1E4156;
`
class FinanceAvailable extends React.Component {
	render() {
		const { data, modalOpen } = this.props
		return(
			<Section pt="122px" pb="117px">
				<div className ="container">
					<SectionTitle>{ data.title }</SectionTitle>
					<MarkdownContent textAlign="center" color="#010920" dangerouslySetInnerHTML={{__html: data.description.childMarkdownRemark.html}} />
					<OptionsGrid>
						<FirstItem>
							<img src={data.features[0].image} alt="green sky" />
							<p>{data.features[0].description}</p>
							{
								data.features[0].options.map(item=> {
									return(
										<Option>
											<CheckIcon /> { item }
										</Option>
									)
								})
							}
							<SectionButtonWrapper textAlign="left">
								<div onClick={()=>modalOpen()}>
									<SectionButton >Get Started</SectionButton>
								</div>
							</SectionButtonWrapper>
						</FirstItem>
					</OptionsGrid>
				</div>
			</Section>
		)
	}
}

export default FinanceAvailable