import React from 'react'
import styled from 'styled-components'
import { FormFields } from './style'
import { StepWrapper, HalfStep } from "../Section"
import StepVerticalVar from "../Icons/StepVerticalVar"
import HalfStepBar from "../Icons/HalfStepBar"
import {CustomValidationMessage, RequiredTriangle} from "../FormStyle"

const Field = styled.div`
    position: relative;
    border-bottom: 1px solid #DCE7EF;
`
const CustomRequired = styled(CustomValidationMessage)`
    top: 90px;
    left: -20px;
		width: fit-content !important;
`

class Step1 extends React.Component {
	render() {
        const { fNameValue, fNameRequire, fNameInputPattern, lNameRequire, lNameInputPattern, lNameValue, ssnRequire, ssnValue, ssnInputPattern, birthRequire, phoneRequire, phoneInputPattern, phoneValue, mobileRequire, mobileInputPattern, mobileValue, emailRequire, emailValue, emailInputPattern, birthInputValue} = this.props
        if (this.props.currentStep !== 1) { // Prop: The current step
          return null
        }
        return(
        	<div>
        		<FormFields>
        			<Field>
        				<label>First Name*</label>
        				<input type="text" name="first_name" placeholder="Please Enter Your First Name" id="first_name" onChange={this.props.handleChange} value={fNameValue} pattern="[A-Za-z\s]+$" />
								<CustomRequired display={fNameInputPattern ==="" ?"none": "block"} width="fit-content"><RequiredTriangle /><span>{fNameInputPattern}</span></CustomRequired>
								<CustomRequired display={fNameRequire ?"block": "none"}><RequiredTriangle /><span>Please input this field</span></CustomRequired>
        			</Field>
        			<Field>
        				<label>Last Name*</label>
        				<input type="text" name="last_name" placeholder="Please Enter Your Last Name" id="last_name" onChange={this.props.handleChange} value={lNameValue} pattern="[A-Za-z\s]+$" />
								<CustomRequired display={lNameInputPattern ==="" ?"none": "block"} width="200px"><RequiredTriangle /><span>{lNameInputPattern}</span></CustomRequired>
								<CustomRequired display={lNameRequire ?"block": "none"}><RequiredTriangle /><span>Please input this field</span></CustomRequired>
        			</Field>
        			<Field>
        				<label>Social Security Number(SSN)*</label>
        				<input type="text" name="ssn" placeholder="Please Enter Your SSN" id="ssn" onChange={this.props.handleChange} value={ssnValue} pattern="[0-9]+$" />
								<CustomRequired display={ssnInputPattern ==="" ?"none": "block"} width="200px"><RequiredTriangle /><span>{ssnInputPattern}</span></CustomRequired>
                <CustomRequired display={ssnRequire ?"block": "none"}><RequiredTriangle /><span>Please input this field</span></CustomRequired>
        			</Field>
        			<Field>
        				<label>Date of Birth*</label>
        				<input type={this.props.dateType} name="birth" placeholder="Tell us your Date of Birth" id="birth" onChange={this.props.handleChange} onFocus={this.props.onFocus} value={birthInputValue} />
                <CustomRequired display={birthRequire ?"block": "none"}><RequiredTriangle /><span>Please input this field</span></CustomRequired>
        			</Field>
        			<Field>
        				<label>Telephone Number*</label>
        				<input type="text" name="phone_no" placeholder="Tell us your Home Number" id="phone_no" onChange={this.props.handleChange} value={phoneValue} pattern="^[0-9]{10}$" />
								<CustomRequired display={phoneInputPattern ==="" ?"none": "block"} width="200px"><RequiredTriangle /><span>{phoneInputPattern}</span></CustomRequired>
                <CustomRequired display={phoneRequire ?"block": "none"}><RequiredTriangle /><span>Please input this field</span></CustomRequired>
        			</Field>
        			<Field>
        				<label>Cell Number*</label>
        				<input type="text" name="mobile_no" placeholder="Tell us your Personal Number" id="mobile_no" onChange={this.props.handleChange} value={mobileValue} pattern="^[0-9]{10}$" />
								<CustomRequired display={mobileInputPattern ==="" ?"none": "block"} width="200px"><RequiredTriangle /><span>{mobileInputPattern}</span></CustomRequired>
                <CustomRequired display={mobileRequire ?"block": "none"}><RequiredTriangle /><span>Please input this field</span></CustomRequired>
        			</Field>
							<Field>
									<label>Email Address*</label>
									<input type="email" name="email" placeholder="Please Enter Your Email Address" id="email" onChange={this.props.handleChange} value={emailValue} pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$" required/>
									<CustomRequired display={emailInputPattern === "" ?"none": "block"} width="200px"><RequiredTriangle /><span>{emailInputPattern}</span></CustomRequired>
									<CustomRequired display={emailRequire ?"block": "none"}><RequiredTriangle /><span>Please input this field</span></CustomRequired>
							</Field>
        		</FormFields>
        		<StepWrapper top="250px" color="#676B79" left="20px">
    		      <div>
    		        <h3 style={{color: "#ED602B"}}>1</h3>
    		        <div style={{color: "#ED602B"}}>
    		          STEP
    		        </div>
    		        <HalfStep>
    		          <HalfStepBar fill="#ED602B" />
    		        </HalfStep>
    		        <StepVerticalVar fill="#010920"/>
    		      </div>
    		      <div>
    		        <h3>2</h3>
    		        <div>
    		          STEP
    		        </div>
    		        <StepVerticalVar fill="#010920"/>
    		      </div>
    		      <div>
    		        <h3>3</h3>
    		        <div>
    		          STEP
    		        </div>
    		        <StepVerticalVar fill="#010920"/>
    		      </div>
    		      <div>
    		        <h3>4</h3>
    		        <div>
    		          STEP
    		        </div>
    		      </div>
        		</StepWrapper>
        	</div>
        )
	}
}

export default Step1