import React from 'react'
import styled from 'styled-components'
import LazyLoad from 'react-lazyload'
import { Link } from 'gatsby'
import { BreadCrumb, MarkdownContent } from "../../components/Section"
import ArrowIcon from "../../components/Icons/ArrowIcon"
import GreenSky from "../../images/greensky-icon.png"
import BenefitCarousel from "../../components/BenefitCarousel"
import MVVideoModal from "../../components/MVVideoModal"
import { StaticImage } from 'gatsby-plugin-image'

const SectionGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	@media(max-width: 1000px) {
		grid-template-columns: repeat(1, 1fr);
	}
`
const Wrapper = styled.div`
	padding-top: 271px;
	padding-bottom: 93px;
	background: #F4FBFF;

	& h1 {
		color: #062C44;
		margin-bottom: 0;
	}

	& p {
		margin-top: 11px;
	}
	@media(max-width: 1200px) {
		padding-top: 200px;
	}
`
const IconWrap = styled.div`
	border-radius: 5px;
	background: rgba(1, 9, 32, 0.05);
	border: 1px solid white;
	margin-right: 10px;
	padding: 10px 20px 5px;
	display: inline-block;
	vertical-align: middle;
	& img {
		height: 34px;
	}
`
const Benefits = styled.div`
	margin-top: 132px;
	& h2 {
		margin-bottom: 12px;
		margin-left: 5px;
		color: #062C44;
	}
	& h3 {
		color: #062C44;
	}
	@media(max-width: 480px) {
		margin-top: 60px;
	}
`
const VideoWrap = styled.div`
	position: relative;
	width: 100%;
	max-width: 600px;
	margin: auto;

	& video {
		width: 100%;
	}
	.play-button {
		position: absolute;
		left: calc(50% - 30px);
		top: calc(50% - 30px);
		translate: trasform(-50%, -50%);
		width: 60px;
		height: 60px;
	
		&:hover{
		  cursor: pointer;
		}
	  }
	  .gatsby-image-wrapper {
		width: 100%;
	}
	
`
const ApplyButton = styled.button`
	border: 0px;
	background: rgba(255, 255, 255, 0.0001);
	color: #ED602B;
	transition: ease 0.5s;
	& svg {
		margin-left: 10px;
		transform: none !important;
		transition: ease 0.3s;
		width: 25px;
	}
	& :hover {
		cursor: pointer;
		text-decoration: none;
		& svg {
			margin-left: 20px;
		}
	}
`
class FinancingHero extends React.Component {
	constructor(props) {
		super(props)
		this.state={
			isVideoModalOpen: false,
			currentVideo: { src: '', type: '' },
		}
		this.handleVideo = this.handleVideo.bind(this);
		this.openVideoModal = this.openVideoModal.bind(this);
		this.closeVideoModal = this.closeVideoModal.bind(this);
		this.applyClicked = this.applyClicked.bind(this);
	}
	playVideo() {
	  this.refs.vidRef.play();
	}
	openVideoModal(url) {
      this.setState({
          currentVideo: {
              type: 'video/youtube',
              src: url
          },
          isVideoModalOpen: true
      });
  }
  closeVideoModal() {
    this.setState({ isVideoModalOpen: false });
  }
  handleVideo() {
    this.openVideoModal('https://www.youtube.com/watch?v=n0yrCDwJbho');
  }
  applyClicked() {
  	const { applyCallBack } = this.props;
  	if (applyCallBack) {
      applyCallBack();
    }
  }
	render(){
		const { data, subTitle } = this.props
		return(
			<Wrapper>
				<MVVideoModal
					isOpen={this.state.isVideoModalOpen}
					onClose={this.closeVideoModal}
				/>
				<div className="container">
					<BreadCrumb top="-60px"><Link to="/">Home</Link> / <Link to="/buying-guide">Buying Guide</Link> / <span>Financing Process</span></BreadCrumb>
					<SectionGrid >
						<div>
							<h1>{data.title}</h1>
							<MarkdownContent dangerouslySetInnerHTML={{__html: data.description.childMarkdownRemark.html}} />
							<div style={{marginTop: 70}}>
								<IconWrap>
									<img src={GreenSky} alt="greensky" />
								</IconWrap>
								<ApplyButton onClick={this.applyClicked}>Apply Now<ArrowIcon fill="#ED602B" /></ApplyButton>
							</div>
						</div>
						<div>
							<LazyLoad>
								<VideoWrap>
								<StaticImage src="../../images/video-poster.png" alt="video poster" />
								<div className="play-button" onClick={this.openVideoModal}>
									<StaticImage src="../../images/play-button.png" alt="play button" />
								</div>
								</VideoWrap>
							</LazyLoad>
						</div>
					</SectionGrid>
					<Benefits>
						<h2>{subTitle}</h2>
						<BenefitCarousel data={data.features} />
					</Benefits>
				</div>
			</Wrapper>
		)
	}
} 

export default FinancingHero