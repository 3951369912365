import React, { Component } from 'react'
import styled from "styled-components"
import Slider from "react-slick";

const Wrapper = styled.div`
  position: relative;
  & .slick-track {
    height: 300px;
    & .slick-slide {
      display: flex;
      align-items: center;
      position: relative;

      & div:first-child {
        width: 100%;
      }
    }
  }
  & ul {
    position: relative !important;
    transform: none !important;
    width: 100% !important;
    top: 0px !important;
    right: 0 !important;

    & li {
      display: inline-block;
    }
  }
  & .slick-dots li.slick-active button:before {
    & li{
      & button {
        & :before {
          font-size: 12px;
          color: #F66226;
        }
      }
      & .slick-active {
        & button {
          &:before {
            color: #ED602B;
          }
        }
      }
    }
  }
`
const Item = styled.div`
  width: 100%;
  margin: 40px 0;
  padding: 40px 20px;
  border-radius: 5px;
  border: 1px solid #98DFFF;
  min-height: 176px;
  height: auto;
  transition: all 0.3s ease;
  background: white;
  & h3 {
    font-size: 24px;
    line-height: 29px;
    color: #062C44;
    margin-bottom: 5px;
  }
  & p {
    text-align: left;
    font-size: 16px;
    line-height: 22px;
    height: 43px;
    overflow: hidden;
    transition: flex 0.3s ease-out;
  }
  & :hover {
    box-shadow: 0px 15px 45px rgba(152, 223, 255, 0.2);
    padding: 40px 20px;
    height: auto;
    min-height: 190px;
    & p {
      height: auto;
      transition:flex 0.3s ease-out;
    }
  }
  & :focus {
    outline: none;
  }

  @media (max-width: 1280px) {
    h3 {
      font-size: 18px;
    }
    p {
      font-size: 13px;
    }
  }

  @media (max-width: 1000px) {
    h3 {
      font-size: 15px;
    }
    p {
      font-size: 11px;
    }
  }
`
const Icon = styled.div`
  width: 15% !important;
  margin-right: 5%;
  display: inline-block;
  vertical-align: top;
`
const BenefitsTextWrap = styled.div`
  width: 80% !important;
  display: inline-block;
  vertical-align: middle;
  
`
export default class BenefitCarousel extends Component {
  render() {
    const { data } = this.props
    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      dots: true,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
          }
        }
      ]
    };
    return (
      <Wrapper >
        <Slider {...settings}>
          {
            data.map((item, i)=>{
              return(
                <Item key={i}>
                  <Icon><img src={item.icon} alt={item.title} /></Icon>
                  <BenefitsTextWrap>
                    <h3>{item.title}</h3>
                    <p>{item.description}</p>
                  </BenefitsTextWrap>
                </Item>
              )
            })
          }
        </Slider>
      </Wrapper>
    );
  }
}