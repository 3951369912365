import React from 'react'
import styled from 'styled-components'
import { FormFields } from './style'
import { StepWrapper, HalfStep } from "../Section"
import StepVerticalVar from "../Icons/StepVerticalVar"
import StepWhiteBar from "../Icons/StepWhiteBar"
import HalfStepBar from "../Icons/HalfStepBar"
import {CustomValidationMessage, RequiredTriangle} from "../FormStyle"

const Field = styled.div`
    position: relative;
    border-bottom: 1px solid #DCE7EF;
`
const CustomRequired = styled(CustomValidationMessage)`
    top: 90px;
    left: -20px;
    width: fit-content !important;
`

class Step2 extends React.Component {
	render() {
    const { sAddressRequire, sAddressInputPattern, sAddressValue, cityRequire, cityInputPattern, cityValue, stateRequire, stateInputPattern, stateValue, zipcodeRequire, zipCodeInputPattern, zipCodeValue, ownRentRequire, ownRentValue, monthlyPaymentRequire, monthlyPaymentInputPattern, monthlyPaymentValue} = this.props
        if (this.props.currentStep !== 2) { // Prop: The current step
          return null
        }
        return(
        	<div>
        		<FormFields>
        			<Field>
        				<label>Street Address*</label>
        				<input type="text" name="street_address" placeholder="Please Enter Your Street Address" id="street_address" onChange={this.props.handleChange} value={sAddressValue} pattern="^[a-zA-Z0-9\s,'-]*$" required />
                <CustomRequired display={sAddressInputPattern ==="" ?"none": "block"} width="fit-content"><RequiredTriangle /><span>{sAddressInputPattern}</span></CustomRequired>
                <CustomRequired display={sAddressRequire ?"block": "none"}><RequiredTriangle /><span>Please input this field</span></CustomRequired>
        			</Field>
        			<Field>
        				<label>City*</label>
        				<input type="text" name="city" placeholder="Please Enter Your City" id="city" onChange={this.props.handleChange} value={cityValue} pattern="^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$" />
                <CustomRequired display={cityInputPattern ==="" ?"none": "block"} width="fit-content"><RequiredTriangle /><span>{cityInputPattern}</span></CustomRequired>
                <CustomRequired display={cityRequire ?"block": "none"}><RequiredTriangle /><span>Please input this field</span></CustomRequired>
        			</Field>
              <Field>
                  <label>State*</label>
                  <input type="text" name="state" placeholder="Please Enter Your State" id="state" onChange={this.props.handleChange} value={stateValue} pattern="[A-Za-z\s]+$" />
                  <CustomRequired display={stateInputPattern ==="" ?"none": "block"} width="fit-content"><RequiredTriangle /><span>{stateInputPattern}</span></CustomRequired>
                  <CustomRequired display={stateRequire ?"block": "none"}><RequiredTriangle /><span>Please input this field</span></CustomRequired>
              </Field>
              <Field>
                  <label>Zip*</label>
                  <input type="text" name="zipcode" placeholder="Please Enter Your ZipCode" id="zipcode" onChange={this.props.handleChange} value={zipCodeValue} pattern="^[0-9]{5}$" />
                  <CustomRequired display={zipCodeInputPattern ==="" ?"none": "block"} width="fit-content"><RequiredTriangle /><span>{zipCodeInputPattern}</span></CustomRequired>
                  <CustomRequired display={zipcodeRequire ?"block": "none"}><RequiredTriangle /><span>Please input this field</span></CustomRequired>
              </Field>
              <Field>
                  <label>Own or Rent*</label>
                  <select name="own_rent" id="own_rent" onChange={this.props.handleChange} value={ownRentValue}>
                    <option value="">-Please Select-</option>
                    <option value="own">Own</option>
                    <option value="rent">Rent</option>
                  </select>
                  <CustomRequired display={ownRentRequire ?"block": "none"}><RequiredTriangle /><span>Please input this field</span></CustomRequired>
              </Field>
              <Field>
                  <label>Monthly Payment*</label>
                  <input type="text" name="monthlyPayment" placeholder="Monthly Payment" id="monthlyPayment" onChange={this.props.handleChange} value={monthlyPaymentValue} pattern="[0-9]+$" />
                  <CustomRequired display={monthlyPaymentInputPattern ==="" ?"none": "block"} width="fit-content"><RequiredTriangle /><span>{monthlyPaymentInputPattern}</span></CustomRequired>
                  <CustomRequired display={monthlyPaymentRequire ?"block": "none"}><RequiredTriangle /><span>Please input this field</span></CustomRequired>
              </Field>
        		</FormFields>
                <StepWrapper top="250px" color="#676B79" left="20px">
                  <div>
                    <h3 style={{color: "#ED602B"}}>1</h3>
                    <div style={{color: "#ED602B"}}>
                      STEP
                    </div>
                    <StepWhiteBar fill="#ED602B"/>
                  </div>
                  <div>
                    <h3 style={{color: "#ED602B"}}>2</h3>
                    <div style={{color: "#ED602B"}}>
                      STEP
                    </div>
                        <HalfStep>
                          <HalfStepBar fill="#ED602B" />
                        </HalfStep>
                    <StepVerticalVar fill="#010920"/>
                  </div>
                  <div>
                    <h3>3</h3>
                    <div>
                      STEP
                    </div>
                    <StepVerticalVar fill="#010920"/>
                  </div>
                  <div>
                    <h3>4</h3>
                    <div>
                      STEP
                    </div>
                  </div>
                </StepWrapper>
        	</div>
        )
	}
}

export default Step2