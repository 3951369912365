import React from 'react'
import styled from 'styled-components'
import { FormFields } from './style'
import { StepWrapper } from "../Section"
import StepWhiteBar from "../Icons/StepWhiteBar"

import {CustomValidationMessage, RequiredTriangle} from "../FormStyle"

const Field = styled.div`
    position: relative;
`
const CustomRequired = styled(CustomValidationMessage)`
    top: 100px;
    left: -10px;
`

class Step4 extends React.Component {
	render() {

    if (this.props.currentStep !== 4) { // Prop: The current step
      return null
    }

    return(
    	<div>
    		<FormFields>
    			<Field>
    				<label>Order Detail</label>
    				<textarea name="order_detail" placeholder="Must be more than 500 charactors" id="order_detail"  rows="6" onChange={this.props.handleChange} />
            <CustomRequired display={this.props.orderDetailRequire ?"block": "none"}><RequiredTriangle /><span>Please input this field</span></CustomRequired>
    			</Field>
    		</FormFields>
            <StepWrapper top="250px" color="#676B79" left="20px">
              <div>
                <h3 style={{color: "#ED602B"}}>1</h3>
                <div style={{color: "#ED602B"}}>
                  STEP
                </div>
                <StepWhiteBar fill="#ED602B"/>
              </div>
              <div>
                <h3 style={{color: "#ED602B"}}>2</h3>
                <div style={{color: "#ED602B"}}>
                  STEP
                </div>
                <StepWhiteBar fill="#ED602B"/>
              </div>
              <div>
                <h3 style={{color: "#ED602B"}}>3</h3>
                <div style={{color: "#ED602B"}}>
                  STEP
                </div>
                <StepWhiteBar fill="#ED602B"/>
              </div>
              <div>
                <h3 style={{color: "#ED602B"}}>4</h3>
                <div style={{color: "#ED602B"}}>
                  STEP
                </div>
              </div>
            </StepWrapper>
    	</div>
    )
	}
}

export default Step4